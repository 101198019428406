<template>
  <div style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="labelTitle" footer-class="footerClass" :id="localId"
      no-close-on-backdrop  content-class="shadow" modal-class="calendar-modal"
      @hidden="$emit('update:show', false)"
      scrollable
    >      
      <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
        <ul :show="showErrorDetail" class="mb-0">
          <template v-for="(item, index) in alertMsgDetails">
            <li :key="index">{{ item }}</li>
          </template>
        </ul>
      </b-alert>
      
      <div class="title">
        <span class="mr-2">{{ $t('calendar.working_week') }}</span>
        <template v-if="!isReadOnly && canEdit() && canDelete()">
          <button class="btn-action btn-lg" :id="`BTN_RESET_${localId}`" @click="useBase"><font-awesome-icon :icon="['fad', 'clock-rotate-left']"/></button>
          <b-popover :target="`BTN_RESET_${localId}`" triggers="hover" placement="top">
            {{ $t('calendar.reset_week_time') }}
          </b-popover>
        </template>
        
      </div>
      
      <ul class="day-list mb-3">
        <WorkingDay :readOnly="isReadOnly" :calendarName="calendarName" :dayIndex="1" :entry="calendarData['Monday']" @add="addEntry" @remove="removeEntry" @canSave="canSave" @history="onHistory"></WorkingDay>
        <WorkingDay :readOnly="isReadOnly" :calendarName="calendarName" :dayIndex="2" :entry="calendarData['Tuesday']" @add="addEntry" @remove="removeEntry" @canSave="canSave" @history="onHistory"></WorkingDay>
        <WorkingDay :readOnly="isReadOnly" :calendarName="calendarName" :dayIndex="3" :entry="calendarData['Wednesday']" @add="addEntry" @remove="removeEntry" @canSave="canSave" @history="onHistory"></WorkingDay>
        <WorkingDay :readOnly="isReadOnly" :calendarName="calendarName" :dayIndex="4" :entry="calendarData['Thursday']" @add="addEntry" @remove="removeEntry" @canSave="canSave" @history="onHistory"></WorkingDay>
        <WorkingDay :readOnly="isReadOnly" :calendarName="calendarName" :dayIndex="5" :entry="calendarData['Friday']" @add="addEntry" @remove="removeEntry" @canSave="canSave" @history="onHistory"></WorkingDay>
        <WorkingDay :readOnly="isReadOnly" :calendarName="calendarName" :dayIndex="6" :entry="calendarData['Saturday']" @add="addEntry" @remove="removeEntry" @canSave="canSave" @history="onHistory"></WorkingDay>
        <WorkingDay :readOnly="isReadOnly" :calendarName="calendarName" :dayIndex="0" :entry="calendarData['Sunday']" @add="addEntry" @remove="removeEntry" @canSave="canSave" @history="onHistory"></WorkingDay>
      </ul>
     
     <ExceptionList :readOnly="isReadOnly" :exceptions="exceptionData" @change="exceptionChange" @delete="exceptionDelete" :calendarName="cName" :baseStartHour="baseStartHour" :baseEndHour="baseEndHour" />
     
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" disabled v-if="state.isSubmitting">
          <b-spinner small type="grow" />{{ $t('button.saving') }}
        </b-button>
        <b-button v-else-if="!readOnly && (canAdd() || canEdit())" :disabled="disableSave" size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <GenericHistoryModal v-if="state.historyShow" :show.sync="state.historyShow" :id="historyId" entityType="CALENDAR" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);
import { msToTime, timeToMs, strRandom, CALENDAR_WEEKDAY_NAME } from '@/helpers';
import { calendarService } from '@/services';

export default {
  name: 'CalendarModal',
  components: {
    WorkingDay: () => import('@/components/Calendar/WorkingDay'),
    GenericHistoryModal: () => import('@/components/modal/GenericHistoryModal'),
    ExceptionList: () => import('@/components/Calendar/ExceptionList')
  },
  props: {
    id:            { type: String, default: null },
    title:         { type: String, default: null },
    show:          { type: Boolean,required: true },
    calendar:      { type: Object, required: true },
    calendarName:  { type: String, default: null },
    calendarOrder: { type: Array,  default: () => [] },
    readOnly:      { type: Boolean, default: false }
  },
  data() {
    return {
      permissionName: 'CALENDAR',
      state: {
        modalShow: false,
        blockSave: false,
        exceptionShow: false,
        baseExceptionShow: false,
        editable: false,
        isSubmitting: false,
        historyShow: false
      },
      historyId: null,
      alertMsg: null,
      alertMsgDetails: [],
      calendarData: {},      
      exceptionData: [],
      event: {},
      toRemove: [],
      baseStartHour: '09:00', //32400000
      baseEndHour: '17:00', //61200000
      baseCalendar: {}
    }
  },
  created() {
    this.localId = `CAL_MODAL_${strRandom(5)}`;
    this.originExceptionData = [];
    this.originCalendarData = {};
  },
  beforeDestroy() {
    this.localId = null;
    this.originExceptionData = null;
    this.originCalendarData = null;
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    disableSave() {
      return this.alertMsg !== null || this.state.blockSave;
    },
    labelTitle() {
      return this.title? this.title: this.$t('calendar.title_new');
    },
    cName() {
      return this.calendarName || '';
    },
    exists() {
      return this.id != null && this.id.includes('_NEW_');
    },
    isLockVisible() {
      return this.canView(this.permissionName, ['readOnly'])
      && ((!this.exists && this.canAdd(this.permissionName, ['readOnly'])) || this.exists)
    },
    isLockReadOnly() {
      return !this.state.editable || this.readOnly || (this.exists && !this.canEdit(this.permissionName, ['readOnly']))
    },
    isReadOnly() {
      return !this.state.editable || this.readOnly || this.$store.state.epoch.value !== null ||
          (this.$store.state.sandbox.value && !this.$store.state.sandbox.canEdit);
    }
  },
  watch: {
    show(newValue) {
      if(newValue != this.state.modalShow) {
        this.state.modalShow = newValue;       
        this.alertMsg = null;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.calendarData = {};
        this.exceptionData.splice(0, this.exceptionData.length);
        this.toRemove = [];  
        this.state.blockSave = false;
        this.state.editable = (!this.exist && this.canAdd(this.permissionName)) || (this.exists && this.canEdit(this.permissionName));
        const calData = this.calendarData;
        const nonDayOfWeek = ['Leave', 'Working'];

        //Set up baseStartHour and baseEndHour
        if (this.calendar.Monday != null && this.calendar.Monday.base_calendar != null && Array.isArray(this.calendar.Monday.base_calendar)) {
          const baseMonday = this.calendar.Monday.base_calendar;
          const startHours = baseMonday.map(item => item.startHour);
          const endHours = baseMonday.map(item => item.endHour);
          if (startHours.length > 0) {
            this.baseStartHour = moment.utc(Math.min(...startHours)).format('HH:mm');
          }
          if (endHours.length > 0) {
            this.baseEndHour = moment.utc(Math.max(...endHours)).format('HH:mm');
          }
        }

        //Defensive code: To set up the default value in case invalid data is provided.
        const calendarOrder = Array.isArray(this.calendarOrder) && this.calendarOrder.length > 0? JSON.parse(JSON.stringify(this.calendarOrder)) : ['base_calendar'];
        let calendarName = this.calendarName != null? this.calendarName : 'base_calendar';
        if (!calendarOrder.includes(calendarName)) {
          calendarName = 'base_calendar';
        }
        
        //Remove order before the provided calendarName because it is the main subject we are dealing now.
        let index = calendarOrder.findIndex(i => i == calendarName);
        if (index > 0) {
          calendarOrder.splice(0, index);
        }
        
        Object.keys(this.calendar).forEach(type => {
          const subCalendar = this.calendar[type];
          if(nonDayOfWeek.includes(type)) {
            Object.keys(subCalendar).forEach(cName => {
              const entries = subCalendar[cName] || [];
              for(const entry of entries) {
                const value = cloneDeep(entry);
                value.calendarName = cName;
                value.startHour = typeof value.startHour !== 'undefined' ? msToTime(value.startHour): null;
                value.endHour = typeof value.endHour !== 'undefined' ? msToTime(value.endHour): null;
                value.type = type;
                this.exceptionData.push(value);
              }
              this.exceptionData.sort(function(a, b) {
                const aStart = new Date(a.startDate);
                const bStart = new Date(b.startDate);
                return aStart < bStart ? -1 : aStart > bStart ? 1 : 0;
              });
            });

            for (const ex of this.exceptionData) {
              if (ex.endHour != null && (ex.endHour == '24:00' || ex.endHour === '24:00:00')) {
                ex.endHour = '00:00';
              }
            }
            this.originExceptionData = cloneDeep(this.exceptionData);

          } else { //Day of Week [Mon to Sun]
            calendarOrder.forEach(cName => {
              const entries = subCalendar[cName];
              if(entries) {
                if (!calData[type]) {
                  calData[type] = [];
                }

                //1. Categorise the calendars into two groups: main group and the other group.
                //Example#1: For calendars ['staff', 'location', 'base_calendar']: Main ['staff'] with name 'staff' and other ['location', 'base_calendar'] with name ['base_calendar']
                //Example#2: For calendars ['location', 'base_calendar']: Main ['location'] with name 'location' and other ['base_calendar'] with name ['base_calendar']

                //[Reason of using 'base_calendar' as name of other group]: Try to minimize the scope of change. It has been used/referenced in other components.

                //2 .For other group, use only the first available calendar.
                //Example#1: For other group, e.g.['location', 'base_calendar']
                //Use location Monday if it is available. Otherwise, use base_calendar Monday.

                let _cName = cName != calendarName? 'base_calendar' : cName;

                if (calData[type].find(i => i.calendarName == _cName) != null) {
                  return; //exists, move to next iteration.
                }

                for(const entry of entries) {
                  const value = cloneDeep(entry);
                  value.startHour = typeof value.startHour !== 'undefined' ? msToTime(value.startHour) : null;
                  value.endHour = typeof value.endHour !== 'undefined' ? msToTime(value.endHour) : null;
                  value.calendarName = _cName;
                  calData[type].push(value);
                }  
              }
            });
          }
        });
        
        //Sort calDta
        const sortTimeFunc = (a, b) => {
          if (a == null && b == null) {
            return 0;
          }
          if (a == null || a.startHour == null) {
            return -1;
          }
          if (b == null || b.startHour == null) {
            return 1;
          }
          if (a.startHour == b.startHour) {
            if (a.endHour == null) {
              return -1;
            }
            if (b.endHour == null) {
              return 1;
            }
            if ( a.endHour > b.endHour) {
              return 1;
            }
            return -1;
          }
          if (a.startHour > b.startHour) {
            return 1;
          }
          return -1;
        }
        for (const typeKey in calData) {
          calData[typeKey].sort(sortTimeFunc);
        }

        //Trick: convert endHour: '24:00' to '00:00'
        //The timepicker doesn't allow '24:00', so '00:00' is used as replacement.
        //When committing the value in calendarSubmit(), convert endHour ('00:00' or '00:00:00') back to '24:00'
        for(const typeKey in calData) {
          const entries = calData[typeKey];
          for (const e of entries) {
            if (e.endHour == null) {
              continue;
            }
            if (e.endHour == '24:00' || e.endHour === '24:00:00') {
              e.endHour = '00:00';
            }
          }
        }

        this.originCalendarData = JSON.parse(JSON.stringify(calData));
      }
    }
  },
  methods: {
    shouldAdd(entry, data) {
      let ret = true;
      for (const d of data) {
        if ((entry.startHour >= timeToMs(d.startHour) &&
            entry.startHour <= timeToMs(d.endHour)) ||
            (entry.endHour >= timeToMs(d.startHour) &&
            entry.endHour <= timeToMs(d.endHour))) {
          ret = false;
          break; // this entry overlaps existing entries   
        }
      }
      return ret;
    },
    hasDupsObjects(array) {
      return array
      .filter(i => i.startHour != null && i.endHour != null)
      .map(function(value) {
        return value.startDate + value.endDate + value.isWorking + value.startHour + value.endHour + value.calendarName;
      }).some(function(value, index, array) { 
        return array.indexOf(value) !== array.lastIndexOf(value);  
      })
    },
    validateEntries() {
      const types = Object.keys(this.calendarData);
      for (const type of types) {
        const entries = this.calendarData[type];
        if (typeof entries !== 'undefined') {
          if (this.hasDupsObjects(entries)) {
            return false;
          }
          
          for (var i = 0; i < entries.length; i++) { 
            if ((!entries[i].startHour && entries[i].endHour) 
                || (entries[i].startHour && !entries[i].endHour)) {
              return false;
            }
          }
        }
      }
      return true;
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
      if (this.checkIfAllWeekdaysNonWork(this.calendarData)) {
        this.alertMsg = this.$i18n.t('calendar.error.at_least_one_working_weekday');
        this.scrollToTop();
        return;
      }
      this.$validator.validate().then(valid => {        
        if (this.validateEntries() && valid && this.errors.items.length < 1) {
          this.calendarSubmit();
        } else {
          this.alertMsg = this.$i18n.t('error.attention_required');
          this.scrollToTop();
        }
      });
    },
    entryCompare(entry, data) {
      if (entry.calendarName !== this.calendarName) {
        return ''; // not modified
      }

      // search the base data for the event
      const calendarNames = Object.keys(data).reverse();
      if (calendarNames[0] === this.calendarName) {
        // move it to the end
        calendarNames.push(calendarNames.shift());
      }
      
      var overriding_base = false; // flag if there is a base calendar this entry overrides
      for(const cName of calendarNames) {
        
        const base_events = data[cName];
        for(const base_event of base_events) {
          if (((base_event.startHour < entry.startHour &&
              base_event.endHour > entry.startHour) ||
              base_event.startHour < entry.endHour &&
              base_event.endHour > entry.endHour) &&
              base_event.startDate === entry.startDate &&
              base_event.endDate === entry.endDate &&
              entry.calendarName !== cName) {
            // this event overlaps the base event so keep it
            if (entry.uuId && !entry.uuId.startsWith('EXCEPTION')) {
              overriding_base = true;
            }   
          }
          else if (base_event.startHour === entry.startHour &&
              base_event.endHour === entry.endHour &&
              base_event.startDate === entry.startDate &&
              base_event.endDate === entry.endDate &&
              entry.calendarName !== cName &&
              !overriding_base) {
            // this event exists in the base so remove it
            if (entry.uuId) {
                  return 'delete';
            }   
          }
          else if (base_event.uuId === entry.uuId && // this event
              (base_event.startHour !== entry.startHour ||
              base_event.endHour !== entry.endHour ||
              base_event.startDate !== entry.startDate ||
              base_event.endDate !== entry.endDate ||
              base_event.name !== entry.name ||
              base_event.isWorking !== entry.isWorking ||
              base_event.identifier !== entry.identifier)) { // changed
            return 'updated';
          }
        }
      }
        
      // not updated and not existing in the base calendars
      if (entry.uuId.startsWith('EXCEPTION')) {
        return 'added';
      }

      //Check if Working or Leave exception has changes
      const found = this.originExceptionData.find(i => i.uuId == entry.uuId);
      if (found != null) {
        let hasChanged = false;
        const keys = Object.keys(found);
        for (let i = 0, len = keys.length; i < len; i++) {
          if (found[keys[i]] !== entry[keys[i]]) {
            hasChanged = true;
            break;
          }
        }
        if (hasChanged) {
          return 'updated';
        }
      }
      
      return ''; // no action
    },
    async calendarSubmit() {
      this.state.isSubmitting = true;
      const calData = this.calendarData;
      //The timepicker don't allow '24:00', so '00:00' is used as replacement.
      //When committing the value in calendarSubmit(), convert endHour ('00:00' or '00:00:00') back to '24:00'
      for(const typeKey in calData) {
        const entries = calData[typeKey];
        for (const e of entries) {
          if (e.endHour == null) {
            continue;
          }
          if (e.endHour == '00:00' || e.endHour == '00:00:00') {
            e.endHour = '24:00';
          }
        }
      }

      const exceptions = this.exceptionData;
      for (const exception of exceptions) {
        const type = exception.type;
        
        if (!calData[type]) {
          calData[type] = [];
        }
        const cloned = cloneDeep(exception);
        if (cloned.endHour != null && (cloned.endHour == '00:00' || cloned.endHour == '00:00:00')) {
          cloned.endHour = '24:00';
        }
        if (cloned.uuId != null && !cloned.uuId.includes('EXCEPTION')) {
          const found = this.originExceptionData.find(i => i.uuId == cloned.uuId);
          if (found != null 
              && found.isWorking == cloned.isWorking
              && found.type == cloned.type
              && found.name == cloned.name
              && found.startDate == cloned.startDate
              && found.startHour == cloned.startHour
              && found.endDate == cloned.endDate
              && found.endHour == cloned.endHour
              && found.identifier == cloned.identifier
              ) {
            continue;
          }
        }
        calData[type].push(cloned);
      }

      const toAdd = [];
      const toUpdate = [];
      const toDelete = [];
      // const self = this;
      const toRemoveValidException = this.toRemove.filter(i => i.uuId != null && !i.uuId.startsWith('EXCEPTION'))
      if(toRemoveValidException.length > 0) {
        toDelete.push(...toRemoveValidException);
      }
      Object.keys(calData).forEach(type => {
        const entries = cloneDeep(calData[type]);

        if (entries) {
          
          const calendarEntry = this.calendar[type] || {};

          if (CALENDAR_WEEKDAY_NAME.includes(type)) {
            const mainEntries = entries.filter(i => i.calendarName == this.calendarName);
            if (mainEntries.length == 0) {
              const originalEntries = this.originCalendarData[type].filter(i => i.calendarName == this.calendarName);
              if (originalEntries.length > 0) {
                toDelete.push(...originalEntries.filter(f => !toDelete.find(ff => f.uuId === ff.uuId)));
              }
              return; //No main calendar entries found. Move to next iteration
            }
            const clonedEntries = JSON.parse(JSON.stringify(mainEntries));
            const workingEntries = clonedEntries.filter(i => i.startHour != null && i.endHour != null);

            //1. When all entries have no valid time, keep/create one non working entry and delete the rest
            if (workingEntries.length == 0) {
              const existingNonWorkEntry = clonedEntries.find(i => i.uuId != null && !i.uuId.startsWith('EXCEPTION') && i.isWorking == false);
              if (existingNonWorkEntry == null) {
                toAdd.push({
                  type
                  , isWorking: false
                  , name: `${this.calendarName} ${type}`
                })
              }
              //Remove the rest.
              const otherEntries = this.originCalendarData[type].filter(i => i.calendarName == this.calendarName && (existingNonWorkEntry == null || i.uuId != existingNonWorkEntry.uuId));
              if (otherEntries.length > 0) {
                toDelete.push(...otherEntries);
              }
            } else {
              //2. When valid entries are found, clean up the non working entry
              //2.1 keep them if not identical to base calendar value 
              //2.2 or, delete them if they are identical to base calendar value.
              
              const existingNonWorkingEntries 
                = this.originCalendarData[type].filter(i => i.calendarName == this.calendarName && !i.isWorking);
              if (existingNonWorkingEntries.length > 0) {
                toDelete.push(...existingNonWorkingEntries);
              }

              let isIdentical = false
              const baseCalendarEntries = this.calendarData[type].filter(i => i.calendarName == 'base_calendar');
              if (baseCalendarEntries.length == workingEntries.length) {
                let matchCount = 0;
                for (const entry of workingEntries) {
                  let matchedIndex = -1;
                  for (const [index, baseEntry] of baseCalendarEntries.entries()) {
                    if (baseEntry.startHour == entry.startHour &&
                        baseEntry.endHour == entry.endHour &&
                        baseEntry.startDate == entry.startDate &&
                        baseEntry.endDate == entry.endDate) {
                      matchedIndex = index;
                      break;
                    }
                  }
                  if (matchedIndex != -1) {
                    baseCalendarEntries.splice(matchedIndex, 1);
                    matchCount++;
                  }
                }
                isIdentical = matchCount == workingEntries.length;
              }
              //Remove the main calendar entries when they are identical to base calendar entries
              if (isIdentical) {
                const originalEntries = this.originCalendarData[type].filter(i => i.calendarName == this.calendarName);
                if (Array.isArray(originalEntries) && originalEntries.length > 0) {
                  toDelete.push(...originalEntries.filter(i => i.isWorking && !toDelete.find(ff => i.uuId === ff.uuId))); //Skip non working entries as they have been added previous.
                }
                return; //Move to next iteration
              }
              
              //Keep the main calendar entries:
              //2.1.1 do nothing when it is identical to original value
              //2.1.2 or, remove old entries and add new entries 

              //Check out if what action the entries need: add or update or dothing.
              let originalEntries = this.originCalendarData[type].filter(i => i.calendarName == this.calendarName);
              
              if (workingEntries.length == originalEntries.length) {
                let matchCount = 0;
                for (const entry of workingEntries) {
                  let matchedIndex = -1;
                  for (const [index, orgEntry] of originalEntries.entries()) {
                    if (orgEntry.startHour == entry.startHour &&
                        orgEntry.endHour == entry.endHour &&
                        orgEntry.startDate == entry.startDate &&
                        orgEntry.endDate == entry.endDate) {
                      matchedIndex = index;
                      break;
                    }
                  }
                  if (matchedIndex != -1) {
                    originalEntries.splice(matchedIndex, 1);
                    matchCount++;
                  }
                }
                isIdentical = matchCount == workingEntries.length;
              }

              if (isIdentical) {
                return; //Do nothing when no change. Move to next iteration
              }

              //Remove the old entries
              originalEntries = this.originCalendarData[type].filter(i => i.calendarName == this.calendarName);
              if (Array.isArray(originalEntries) && originalEntries.length > 0) {
                // we want to update the original entries so that we keep the history
                toUpdate.push(...workingEntries.filter(f => !f.uuId.startsWith('EXCEPTION_')));
                for (const entry of toUpdate) {
                  // remove the entries we are updating from the workingEntries so that they don't get added again
                  const idx = workingEntries.findIndex(f => f.uuId === entry.uuId);
                  if (idx !== -1) {
                    workingEntries.splice(idx, 1);
                  }
                }
              }
              
              //Add new entries
              toAdd.push(...workingEntries.map(i => { return { name: i.name, startHour: timeToMs(i.startHour), endHour: timeToMs(i.endHour), type: i.type, isWorking: true }}));
            }

          } else {
            for(const entry of entries) {
              if (entry.startHour) {
                entry['startHour'] = timeToMs(entry.startHour);
              }
              else {
                delete entry['startHour']; // = new Date('1970-01-01T00:00').getTime();
              }
              
              if (entry.endHour) {
                entry['endHour'] = timeToMs(entry.endHour);
              }
              else {
                delete entry['endHour'];// = new Date('1970-01-01T24:00').getTime();
              }           
              
              const result = this.entryCompare(entry, calendarEntry);
              
              if (result === 'added') {
                delete entry['label'];
                delete entry['uuId'];
                delete entry['calendarName'];
                //Make sure isWorking is true for weekday which has startHour and endHour.
                if (CALENDAR_WEEKDAY_NAME.includes(entry.type) && entry.startHour != null && entry.endHour != null) {
                  entry.isWorking = true;
                }
                toAdd.push(entry);
                
              }
              else if (result === 'updated') {
                delete entry['label'];
                delete entry['calendarName'];
                toUpdate.push(entry);
              }
              else if (result === 'delete' && !entry.uuId.startsWith('EXCEPTION')) {
                //Update the entry instead of deleting it. Update action matches with user's update action and will be recorded in history log.
                delete entry.calendarName;
                toUpdate.push(entry);
              }
            }
          }

        }
      });

      const errors = []
      let result = null;
      if(toDelete.length > 0) {
        result = await this.updateCalendar('remove', toDelete.map(i => { return {uuId: i.uuId, name: i.name }}));
        if(result && result.errors && result.errors.length > 0) {
          errors.push(...result.errors);
        }
      }

      if(toUpdate.length > 0) {
        for(const entry of toUpdate) {
          delete entry.calendarName;
          if (entry.startHour) {
            entry['startHour'] = timeToMs(entry.startHour);
          } else {
            delete entry['startHour'];
          }
          
          if (entry.endHour) {
            entry['endHour'] = timeToMs(entry.endHour);
          } else {
            delete entry['endHour'];
          }
        }
        result = await this.updateCalendar('update', toUpdate);
        if(result && result.errors && result.errors.length > 0) {
          errors.push(...result.errors);
        }
      }

      if(toAdd.length > 0) {
        result = await this.updateCalendar('create', toAdd);
        if(result && result.errors && result.errors.length > 0) {
          errors.push(...result.errors);
        }
      }

      if(errors.length > 0) {
        this.alertMsgDetails.splice(0, this.alertMsgDetails, ...errors);
        this.alertMsg = this.$t('calendar.error.failed_to_update_calendar');
        this.scrollToTop();
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t('calendar.update') });
      }
      this.state.isSubmitting = false;
    },
    async updateCalendar(method, data) {
      let result = {
        hasError: false,
        msg: this.$t(`calendar.update`),
        errors: []
      }
      await calendarService[method](data, this.id)
      .then(response => {
        if(response && 207 == response.status) {
          result.hasError = true;
          result.msg = this.$t(`calendar.error.failed_to_update_calendar`);
          const list = response.data[response.data.jobCase];
          errorHandling(list, result, data, this);
        }
      })
      .catch((e) => {
        result.hasError = true;
        result.msg = this.$t(`calendar.error.failed_to_update_calendar`);
        if(e.response && 422 == e.response.status) {
          const list = e.response.data[e.response.data.jobCase];
          errorHandling(list, result, data, this);
        }
      });
        
      return result;


      function errorHandling(list, result, data, self) {
        const exceptionType = ['Leave', 'Working'];
        for(let i = 0, len = list.length; i < len; i++) {
          const fail = data[i];
          if('not_allowed' === list[i].clue) {
            if(exceptionType.includes(fail.type)) {
              result.errors.push(self.$t(`calendar.error.failed_to_${method}_exception_date_clash`, [data[i].name]));
            }
          } else {
            result.errors.push(self.$t(`calendar.error.failed_to_${method}_exception`, [data[i].name]));
          }
        }
      }
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    addEntry(obj) {
      const entry = obj.value;
      const replace = obj.replace;
      
      if (replace) {
        const index = this.calendarData[entry.type].indexOf(entry);
        if (index > -1) {
          this.calendarData[entry.type].splice(index, 1);
        }
      } else if (this.calendarData[entry.type].length == 1 && entry.calendarName != this.calendarName) {
        // Convert the base entry to location: remove the base entry and add new location entry
        // The logic is developed on assumption: base calendar contains only one entry per type. This is told by Chrism.
        const clone = JSON.parse(JSON.stringify(this.calendarData[entry.type][0]));
        clone.uuId = `EXCEPTION_${strRandom(5)}`;
        clone.name = this.calendarName + ' ' + entry.type;
        clone.calendarName = this.calendarName;
        this.calendarData[entry.type].splice(0, 1, clone);
      }
      
      // if the entry is from the base clone all entries
      if (entry.calendarName !== this.calendarName) {
        var entries = cloneDeep(this.calendarData[entry.type]);
        this.calendarData[entry.type].splice(0, this.calendarData[entry.type].length);
        for (var i = 0; i < entries.length; i++) {
          entries[i].calendarName = this.calendarName;
          entries[i].uuId = `EXCEPTION_${strRandom(5)}`;
          this.calendarData[entry.type].splice(0, this.calendarData[entry.type].length, entries[i]);
        }
      }
      
      var newentry = cloneDeep(entry);
      delete newentry['createdAt'];
      if (newentry.startHour !== '') {
        if (!replace) {
          const startHour =  moment.utc('1970-01-01T' + newentry.endHour, 'YYYY-MM-DDTHH:mm');
          if (startHour.hours() < 24) {
            startHour.minutes(startHour.minutes());
          }
          newentry.startHour = startHour.format('HH:mm');
        }
      }
      else {
        newentry.startHour = '';
        newentry.isWorking = false;
      }
      
      if (newentry.endHour !== '') {
        if (!replace) {
          // set the initial duration to 1 hour
          const endHour = moment.utc('1970-01-01T' + newentry.endHour, 'YYYY-MM-DDTHH:mm');
          if (endHour.hours() < 24) {
            endHour.hours(endHour.hours() + 1);
          }
          newentry.endHour = endHour.format('HH:mm');
          newentry.isWorking = true;
        }
      }
      else {
        newentry.endHour = '';
        newentry.isWorking = false;
      }
      newentry.uuId = `EXCEPTION_${strRandom(5)}`;
      newentry.name = this.calendarName + ' ' + entry.type;
      newentry.calendarName = this.calendarName;
      this.calendarData[entry.type].splice(this.calendarData[entry.type].length, 0, newentry);
    },
    removeEntry(entry) {
      if (!entry.uuId.startsWith('EXCEPTION')) {
        this.toRemove.push(cloneDeep(entry));
      }
      
      if (typeof this.calendarData[entry.type] === 'undefined') {
        return; // cannot delete
      }
      
      const index = this.calendarData[entry.type].indexOf(entry);
      if (index > -1) {
        this.calendarData[entry.type].splice(index, 1);
      }
      
    },
    canSave(can) {
      if (can) {
        this.alertMsg = null;
      }
      this.state.blockSave = !can;
    },
    getCalendarEntries(key, level) {
      const subCalendar = this.calendar[key];
      for (var i = level; i < this.calendarOrder.length; i++) {
        var entry = subCalendar[this.calendarOrder[i]];
        if (typeof entry !== 'undefined' && this.calendarData[key].length === 0 &&
            entry.length > 0) {
          this.calendarData[key] = [];
          for (var index in entry) {
            var value = cloneDeep(entry[index]);
            if (typeof value.startHour !== 'undefined') {
              value.startHour = msToTime(value.startHour);
              value.endHour = msToTime(value.endHour);
            }
            else {
              value.startHour = '';
              value.endHour = '';
            }
            value.calendarName = this.calendarOrder[i];
            this.calendarData[key].splice(0, 0, value);
          }
        }
      }
    },
    useBase() {
      for (var key in this.calendarData) {
        if (key !== 'Leave' && key !== 'Working') {
          var entries = this.calendarData[key];
          for (var i = entries.length - 1; i >= 0; i--) {
            var entry = entries[i];
            if (entry.calendarName === this.calendarName) {
              this.toRemove.push({ uuId: entry.uuId });
              entries.splice(i, 1);
            }
          }
          
          if (entries.length === 0) {
            this.getCalendarEntries(key, 1);
          }
        }
      }
      this.calendarData = cloneDeep(this.calendarData);
    },
    exceptionChange(event) {
      event.type = event.isWorking? 'Working':'Leave';
      const index = this.exceptionData.findIndex(i => i.uuId === event.uuId);
      if(index > -1) {
        this.exceptionData.splice(index, 1, event);
      } else {
        this.exceptionData.push(event);
      }
      
      this.exceptionData.sort(function(a, b) {
        const aStart = new Date(a.startDate);
        const bStart = new Date(b.startDate);
        return aStart < bStart ? -1 : aStart > bStart ? 1 : 0;
      });
    },
    exceptionDelete(eventIds) {
      let index = -1;
      eventIds.forEach(id => {
        index = this.exceptionData.findIndex(i => i.uuId === id);
        if(index > -1) {
          const event = this.exceptionData[index];
          if(!event.uuId.startsWith("EXCEPTION")) {
            this.toRemove.push(cloneDeep(this.exceptionData[index]));
          }
          this.exceptionData.splice(index, 1);
        }
      });
    },
    scrollToTop() {
      const elem = document.querySelector(`#${this.localId}___BV_modal_body_`);
      elem.scrollTop = 0;
    },
    initEventObj(reset = false) {
      if(reset || !this.event) {
        this.event = {
          uuId: `EXCEPTION_NEW_${strRandom(5)}`,
          name: null,
          startDate: null,
          endDate: null,
          startHour: null,
          endHour: null,
          isWorking: false
        }
      }
    },
    convertTimeStrToNumber(entries) {
      for(const entry of entries) {
        if (entry.startHour) {
          entry['startHour'] = timeToMs(entries.startHour);
        } else {
          delete entry['startHour'];
        }
        
        if (entry.endHour) {
          entry['endHour'] = timeToMs(entries.endHour);
        } else {
          delete entry['endHour'];
        }
      }
    },
    checkIfAllWeekdaysNonWork(calData) {
      for (const wDay of CALENDAR_WEEKDAY_NAME) {
        if (!this.isWeekdayNonWork(calData[wDay])) {
          return false;
        }
      }
      return true;
    },
    isWeekdayNonWork(weekDay) {
      if (!Array.isArray(weekDay) || weekDay.length == 0) {
        return true;
      }
      const mainEntries = weekDay.filter(i => i.calendarName == this.calendarName);
      if (mainEntries.length > 0) {
        return !mainEntries.some(i => i.startHour != null && i.endHour != null);
      }
      if (this.calendarName == 'base_calendar') {
        return true;
      }
      const baseEntries = weekDay.filter(i => i.calendarName == 'base_calendar');
      if (baseEntries.length > 0) {
        return !baseEntries.some(i => i.startHour != null && i.endHour != null);
      }
      return true;
    },
    onHistory(value) {
      this.historyId = value.uuId;
      this.state.historyShow = true;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style.scss";
  
  .calendar-modal {
    .exception-grid-height {
      height: 250px;
      min-height: 250px;
      margin-bottom: 10px;
    }
    .day-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    .title {
      font-size: 1.2em;
      span {
        display: inline-block;
        line-height: 2;
      }
    }
  }
</style>
